<template>
  <div class="drone">
    <template v-for="{ node, point, compo, key } of points">
      <component
        :is="compo"
        :key="key"
        :info="node.info"
        :point="point"
        :status="node.status"
        :msg="node.msg"
        v-bind="$attrs"
      ></component>
    </template>
  </div>
</template>

<script>
import Status from './status.vue';
import Control from './control.vue';
import Monitor from './monitor.vue';
import Debug from '@/components/debug.vue';
import Battery from '@/components/battery.vue';
import DroneMap from './map.vue';
import Custom from '@/components/custom/custom.vue';
import Settings from '@/components/settings/settings.vue';
import Speaker from '@/components/speaker/speaker.vue';

const CompoName = {
  'drone_status': Status.name,
  'map': DroneMap.name,
  'debug': Debug.name,
  'battery': Battery.name,
  'custom': Custom.name,
  'console': Control.name,
  'settings': Settings.name,
  'speaker': Speaker.name,
  'iframe': Monitor.name,
  'livestream_img': Monitor.name,
  'livestream_flv': Monitor.name,
  'livestream_hls': Monitor.name,
  'livestream_webrtc': Monitor.name,
  'livestream_webrtc2': Monitor.name,
  'livestream_webrtc3': Monitor.name,
  'livestream_webrtc4': Monitor.name,
  'livestream_webrtc_srs': Monitor.name
};

const CompoOrder = {
  [Status.name]: 0,
  [Monitor.name]: 2,
  [DroneMap.name]: 3,
  [Speaker.name]: 4,
  [Control.name]: 9,
  [Settings.name]: 10,
  [Custom.name]: 20,
  [Battery.name]: 30,
  [Debug.name]: 99,
};

export default {
  name: 'sd-node-drone',
  inheritAttrs: false,
  props: {
    /** @type {Vue.PropOptions<SDWC.Node>} */
    node: {
      type: Object,
      required: true
    }
  },
  computed: {
    /** @returns {SDWC.Node[]} */
    drones() { return this.$store.getters.drones; },
    /** @returns {{ node: SDWC.Node, point: SDWC.NodePoint, compo: string, key: string }[]} */
    points() {
      return this.node.info.points.map((point, index) => {
        const { node_id, point_type_name } = point;
        const node = this.drones.find(n => n.info.id === node_id);
        const compo = CompoName[point_type_name] || '';
        const key = `${node_id}-${point_type_name}-${index}`;
        return { node, point, compo, key };
      }).sort((a, b) => CompoOrder[a.compo] - CompoOrder[b.compo]);
    }
  },
  components: {
    [Debug.name]: Debug,
    [Status.name]: Status,
    [Battery.name]: Battery,
    [Custom.name]: Custom,
    [Control.name]: Control,
    [DroneMap.name]: DroneMap,
    [Monitor.name]: Monitor,
    [Settings.name]: Settings,
    [Speaker.name]: Speaker
  }
};
</script>
